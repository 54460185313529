.folderItem{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:10px 0px;
    border-radius: 5px;
    position:relative;
}
.folderItem:hover{
  cursor: pointer;
  background-color:#f9f9f9;
}
.folderItem .icon{
  font-size:120px;
  color:#f7d577;
  flex:1;
  max-height: 120px;
  width: 100%;
  object-fit: contain;
}
.folderItem .text{
  font-size: 0.8rem;
  line-height: 2rem;
  text-align: center;
  color:rgb(75, 75, 75);
  flex:1;
}
.folderItem .progress{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.1);
  font-size: 2.5rem;
  line-height: 100%;
  color:white;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.folderItem .shared_wrapper{
  position: absolute;
  right:13px;
  bottom:23px;
  color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.folderItem .shared_wrapper .shared_text{
  font-size: 1.2rem;
  margin-right: 3px;
  font-size: 0.75rem;
}
.folderItem .shared_wrapper .shared_icon{
  font-size: 0.85rem;
  color: #f9f9f9;
}

.breadcrumb{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding:10px 0px;
}
.breadcrumb .item{
  line-height: 1.5rem;
  font-size: 0.8rem;
  margin-right:10px;
  text-decoration: none;
  color: rgb(75, 75, 75);
}
.breadcrumb .item:hover{
  cursor: pointer;
  color: darkgray;
  text-decoration: underline;
}
