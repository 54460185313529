body {
  margin: 0;
  line-height: 0.8!important;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-section {
  display:flex;
  flex-direction: row;
  height: 80px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}


.bottom-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.nav {
  display: flex;
  flex-direction: row;
}

.main {
  flex-grow: 1;
}

.text-seperate::before{
  content: "•";
  margin: 0 4px;
}
.shared-badge{
  font-size: 0.75rem;
  padding:5px;
  border-radius: 2px;
  background-color: rgb(237, 247, 237) ;
  color: rgb(30, 70, 32);
}
.claimed-badge {
  margin-left: 5px;
  font-size: 0.75rem;
  padding:5px;
  border-radius: 2px;
  background-color: rgb(229, 246, 253);
  color: rgb(1, 67, 97);
}

.nav-item{
  width: 120px!important;
}
.nav-item p{
  margin-left: 10px;
}
.nav-item.active{
  color: #F777B0;
}
.nav-item.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px; 
  border-bottom: 2px solid #F777B0; /* 示例边框颜色和厚度 */
}
.nav-item.active p{
  color: #F777B0;
}

.stepItem{
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin:10px 0px;
  border-radius: 5px;
  cursor: pointer;
  align-items: flex-end;
  justify-content: center;
}
.stepTitle{
  color: #1a1a1a!important;
}
.stepItem h5{
  font-size:1.5rem;
  color: #999;
}
.stepItem h6{
  color: #1a1a1a;
}
.stepItem:hover{
  background-color: #efefef;
}
.stepItem.active{
  background-color: #efefef;
  position: relative;
}
