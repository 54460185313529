.support-format{
    font-size: 1rem;
    text-align: center;
}

.image-item:hover{
    cursor: pointer;
}

/* 样式文件，例如 styles.css */
.droppableArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background-color 0.3s ease; /* 平滑背景色过渡 */
    padding: 50px;
    border: 3px dashed grey; /* 虚线边框 */
    border-radius: 5px;
    background-color: #fff8fb; /* 高亮背景色 */
  }
  
  .droppableAreaHighlight {
    background-color: #f7b8d4; /* 高亮背景色 */
  }
  